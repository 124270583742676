import * as React from 'react';
import {useState} from 'react';
import { useAllPosts } from '../operations/queries/allPosts';
import { AllPosts as AllPostsProps } from '../models/AllPosts';
import { renderRichText } from "gatsby-source-contentful/rich-text"
import options from '../utils/getRichTextOptions';
import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';

import "../theme/layout.scss"
import HeaderLayout from '../components/layout/HeaderLayout';

import ModalSuscribe from '../components/layout/ModalSuscribre';


export default function Todos( props:any ): JSX.Element {


    const data: AllPostsProps = useAllPosts();
    
    //functions modal suscribe
    const [open, setOpen] = useState(false);
    function openSuscribe(){
        setOpen(true);
    }
    function closeSuscribe(){
        setOpen(false);
    }
    // console.log(data)

    return (
        <>
            <HeaderLayout openModal={openSuscribe} mb={true} ticker={false}/>
            <div className='wrap-content'>
              <div className='header--section'>
                <h2 className='title--section'>Histórico</h2>
                </div>
                {data && data.todos.map(( node ) => {
                    const { title, description, author, category, date, slug } = node

                    const link = category == "Mercados a la Apertura" || category == "Estrategia de Inversion" ? "/" + slug : slug

                    // console.log(link)
                    return (
                        
                        <div className="item">
                            <p className="date">
                                {date}
                            </p>
                            <h2 className="title">{title}</h2>
                            <div className="resume-content">
                                {
                                description?.raw ? 
                                renderRichText(description,options)
                                : 
                                    description
                                }
                            </div>
                            <div className="item--footer">

                                <p className="autor">
                                    {author}
                                </p>
                                <a href={link} >
                                    <div className="link">Ver más</div>
                                </a>
                            </div>
                        </div>
                    )
                
                })}
            </div>
            <CustomFooter openModal={openSuscribe}/>
            <GdprCookies />
            {
                open && <ModalSuscribe closeModal={closeSuscribe}/>
            }
        </>

    )
}

// export default Todos