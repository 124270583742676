import { graphql, useStaticQuery } from 'gatsby';
// import {
//   ContentfulPulsoTecnico,
//   ContentfulDiarioEconomico,
//   ContentfulMercadosALaApertura,
//   ContentfulEstrategiaDeInversion,
//   ContentfulGenerico,
//   ContentfulFlashEmisora,
//   ContentfulFlashTecnico,
//   ContentfulFlashEconomico,
//   ContentfulFlashInternacional,
//   ContentfulFlashRentaFija,
//   ContentfulReporteTrimestral,
// } from '../../../types/graphql-types';
// import parseDate from '../../utils/parseDate';
import { AllPosts as AllPostsProps } from '../../models/AllPosts';

export const useAllPosts = (): AllPostsProps => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }


    }
  `);
  // const reporteTrimistralPosts = data.reporteTrimiestral.edges.map(
  //   (edge: { node: ContentfulReporteTrimestral }) => {
  //     const {
  //       id,
  //       titulo,
  //       resumen,
  //       slug,
  //       createdAt,
  //       autor,
  //       heroImage,
  //     } = edge.node;
  //     const categorySlug = 'renta-variable';
  //     return {
  //       id,
  //       title: titulo,
  //       description: resumen,
  //       category: 'Renta Variable',
  //       slug: `/renta-variable/${slug}`,
  //       date: parseDate(createdAt),
  //       createdAt,
  //       categorySlug,
  //       author: autor?.nombre,
  //       fluid: heroImage,
  //     };
  //   }
  // );
  // const pulsoTecnicoPosts = data.pulsoTecnico.edges.map(
  //   (edge: { node: ContentfulPulsoTecnico }) => {
  //     const {
  //       id,
  //       titulo,
  //       resumen,
  //       slug,
  //       createdAt,
  //       autor,
  //       heroImage,
  //     } = edge.node;
  //     const categorySlug = 'diario-tecnico';
  //     return {
  //       id,
  //       title: titulo,
  //       description: resumen,
  //       category: 'Diario Técnico',
  //       //slug: `/tecnico/${categorySlug}/${slug}`,
  //       slug: `/tecnico/${slug}`,
  //       date: parseDate(createdAt),
  //       createdAt,
  //       categorySlug,
  //       author: autor?.nombre,
  //       fluid: heroImage,
  //     };
  //   }
  // );

  // const genericoPosts = data.generico.edges.map(
  //   (edge: { node: ContentfulGenerico }) => {
  //     const {
  //       id,
  //       titulo,
  //       resumen,
  //       slug,
  //       createdAt,
  //       autor,
  //       heroImage,
  //     } = edge.node;
  //     const categorySlug = 'temas-del-momento';
  //     return {
  //       id,
  //       title: titulo,
  //       description: resumen,
  //       category: 'Temas del Momento',
  //       //slug: `/tecnico/${categorySlug}/${slug}`,
  //       slug: `/temas-del-momento/${slug}`,
  //       date: parseDate(createdAt),
  //       createdAt,
  //       categorySlug,
  //       author: autor?.nombre,
  //       fluid: heroImage,
  //     };
  //   }
  // );

  // const diarioEconomicoPosts = data.diarioEconomico.edges.map(
  //   (edge: { node: ContentfulDiarioEconomico }) => {
  //     const {
  //       id,
  //       titulo,
  //       resumen,
  //       slug,
  //       createdAt,
  //       autor,
  //       fotoDePortada,
  //     } = edge.node;
  //     const categorySlug = 'diario-economico';
  //     return {
  //       id,
  //       title: titulo,
  //       description: resumen,
  //       category: 'Diario Económico',
  //       slug: `/economico/${slug}`,
  //       date: parseDate(createdAt),
  //       createdAt,
  //       categorySlug,
  //       author: autor?.nombre,
  //       fluid: fotoDePortada,
  //     };
  //   }
  // );

  // const mercadoAperturaPosts = data.mercadosApertura.edges.map(
  //   (edge: { node: ContentfulMercadosALaApertura }) => {
  //     const {
  //       id,
  //       titulo,
  //       resumen,
  //       slug,
  //       createdAt,
  //       autor,
  //       comentarioDiario,
  //       autorComentarioDiario,
  //       fotoDePortada,
  //       mercadosEnElMundo,
  //       imagenApertura,
  //     } = edge.node;
  //     const categorySlug = 'mercados-a-la-apertura';
  //     return {
  //       id,
  //       title: titulo,
  //       description: resumen,
  //       category: 'Mercados a la Apertura',
  //       slug: `${categorySlug}/${slug}`,
  //       date: parseDate(createdAt),
  //       createdAt,
  //       categorySlug,
  //       author: autor?.nombre,
  //       authorObject: {
  //         name: autorComentarioDiario?.nombre,
  //         twitter: autorComentarioDiario?.twitter,
  //         avatar: autorComentarioDiario?.foto,
  //       },
  //       fluid: fotoDePortada,
  //       comentarioDiario: comentarioDiario?.texto,
  //       tituloComentarioDiario: comentarioDiario?.titulo,
  //       autorComentarioDiario: {
  //         id: comentarioDiario?.autor?.nombre,
  //         name: comentarioDiario?.autor?.nombre,
  //         twitter: comentarioDiario?.autor?.twitter,
  //         bio: comentarioDiario?.autor?.bio,
  //         avatar: comentarioDiario?.autor?.foto,
  //       },
  //       mercadosEnElMundo: mercadosEnElMundo?.json,
  //       imagenApertura: imagenApertura,
  //     };
  //   }
  // );

  // const estrategiaDeInversionPosts = data.estrategiaDeInversion.edges.map(
  //   (edge: { node: ContentfulEstrategiaDeInversion }) => {
  //     const {
  //       id,
  //       titulo,
  //       resumen,
  //       slug,
  //       createdAt,
  //       autor,
  //       heroImage,
  //     } = edge.node;
  //     const categorySlug = 'estrategia-de-inversion';
  //     return {
  //       id,
  //       title: titulo,
  //       description: resumen,
  //       category: 'Estrategia de Inversion',
  //       slug: `${categorySlug}/${slug}`,
  //       createdAt,
  //       date: parseDate(createdAt),
  //       categorySlug,
  //       author: autor?.nombre,
  //       fluid: heroImage,
  //     };
  //   }
  // );

  // const flashEmisoraPosts = data.flashEmisora.edges.map(
  //   (edge: { node: ContentfulFlashEmisora }) => {
  //     const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
  //     const categorySlug = 'flash';
  //     return {
  //       id,
  //       title: titulo,
  //       description: resumen,
  //       category: 'Flash Emisora',
  //       slug: `/renta-variable/${slug}`,
  //       date: parseDate(createdAt),
  //       categorySlug,
  //       author: autor?.nombre,
  //       createdAt
  //     };
  //   }
  // );

  // const flashTecnicoPosts = data.flashTecnico.edges.map(
  //   (edge: { node: ContentfulFlashTecnico }) => {
  //     const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
  //     const categorySlug = 'flash';
  //     return {
  //       id,
  //       title: titulo,
  //       description: resumen,
  //       category: 'Flash Técnico',
  //       //slug: `/tecnico/${categorySlug}/${slug}`,
  //       slug: `/tecnico/${slug}`,
  //       date: parseDate(createdAt),
  //       categorySlug,
  //       author: autor?.nombre,
  //       createdAt
  //     };
  //   }
  // );

  // const flashEconomicoPosts = data.flashEconomico.edges.map(
  //   (edge: { node: ContentfulFlashEconomico }) => {
  //     const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
  //     const categorySlug = 'flash';
  //     return {
  //       id,
  //       title: titulo,
  //       description: resumen,
  //       category: 'Flash Económico',
  //       slug: `/economico/${slug}`,
  //       date: parseDate(createdAt),
  //       categorySlug,
  //       author: autor?.nombre,
  //       createdAt
  //     };
  //   }
  // );

  // const flashInternacionalPosts = data.flashInternacional.edges.map(
  //   (edge: { node: ContentfulFlashInternacional }) => {
  //     const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
  //     const categorySlug = 'flash';
  //     return {
  //       id,
  //       title: titulo,
  //       description: resumen,
  //       category: 'Flash Internacional',
  //       slug: `/internacional/${slug}`,
  //       date: parseDate(createdAt),
  //       categorySlug,
  //       author: autor?.nombre,
  //       createdAt
  //     };
  //   }
  // );

  // const flashRentaFijaPosts = data.flashRentaFija.edges.map(
  //   (edge: { node: ContentfulFlashRentaFija }) => {
  //     const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
  //     const categorySlug = 'flash';
  //     return {
  //       id,
  //       title: titulo,
  //       description: resumen,
  //       category: 'Flash Renta Fija',
  //       slug: `/renta-fija/${slug}`,
  //       date: parseDate(createdAt),
  //       categorySlug,
  //       author: autor?.nombre,
  //       createdAt
  //     };
  //   }
  // );

  // lista de publicaciones
  const listaDePublicaciones: unknown[] = [];
  // listaDePublicaciones.push(...estrategiaDeInversionPosts);
  // listaDePublicaciones.push(...diarioEconomicoPosts);
  // listaDePublicaciones.push(...mercadoAperturaPosts);
  // listaDePublicaciones.push(...pulsoTecnicoPosts);
  // listaDePublicaciones.push(...genericoPosts);
  // listaDePublicaciones.push(...reporteTrimistralPosts);

  // listaDePublicaciones.push(...flashEmisoraPosts);
  // listaDePublicaciones.push(...flashEconomicoPosts);
  // listaDePublicaciones.push(...flashTecnicoPosts);
  // listaDePublicaciones.push(...flashInternacionalPosts);
  // listaDePublicaciones.push(...flashRentaFijaPosts);

  // console.log('lista de publicaciones');
  // console.log(listaDePublicaciones);

  // sacar el featured (el mas reciente de todos)
  var listaSort: any[] = listaDePublicaciones.sort(function (a: any, b: any) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return +new Date(b.createdAt).getTime() - +new Date(a.createdAt).getTime();
  });

  // console.log(listaSort);

  const all: AllPostsProps = {
    todos: listaSort
  };

  return all;
};
